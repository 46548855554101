import http from "@/utils/http";

const report = {
  monthdetailed(data) {
    return http.post("/api/report/monthdetailed", data);
  },
  daydetailed(data) {
    return http.post("/api/report/daydetailed", data);
  },
  monthlist(data) {
    return http.post("/api/report/monthlist", data);
  },
  daylist(data) {
    return http.post("/api/report/daylist", data);
  },
};

export default report;
