import http from './http'

const exportFile = {
  /**
   * post方式导出
   * @param url 后台提供接口
   * @param args 接口需要参数
   */
  exportFilePost: function (url, args, fileName='') {
    // args.reportType = 'export'
    //
    // let oldDom = document.getElementById("exportIFrame")
    // if (oldDom) {
    //   document.body.removeChild(oldDom)
    // }
    // let orgin = window.location.origin
    // url = orgin + url
    //
    // let iframe = document.createElement("iframe");
    // iframe.style.height = '0'
    // iframe.style.display = 'none';
    // iframe.id = "exportIFrame";
    // document.body.appendChild(iframe);
    //
    // let form =`<form id="exportForm" action="${url}" method="post">`
    // for (let key in args) {
    //   form += `<input type="hidden" name="${key}" value="${args[key]}"/>`
    // }
    // form = form + "</form> "
    //
    // let doc = document.getElementById("exportIFrame").contentDocument;
    // doc.body.innerHTML = form
    // doc.getElementById('exportForm').submit()

    http.post(url, args, {responseType: 'blob'}).then((res) => {
      let blob = new Blob([res], {type: 'application/vnd.ms-excel;charset=utf-8'});
      let a = document.createElement('a');
      a.style.display = 'none';
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      let body = document.getElementsByTagName('body')[0];
      body.appendChild(a);
      a.click();
      setTimeout(() => {
        body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0)
    })
  }
}

export default exportFile