/**Created by liaoyingchao on 11/25/22.*/

<template>
  <div class="order page-css list-css" v-loading="pageLoading">
    <div class="top-tools">
      <el-form ref="RefFilterForm" :model="form" label-width="90px">
        <div class="filter-items">
          <el-form-item class="filter-item" label="销售日期">
            <el-date-picker
              v-model="form.time"
              type="month"
              :disabled-date="disabledDate"
              placeholder="请选择日期"
              :clearable="false"
              format="YYYY-MM"
              value-format="YYYY-MM-DD HH:mm:ss"
            />
          </el-form-item>
          <el-form-item class="filter-item" label="订单状态">
            <el-select
              v-model="form.orderState"
              placeholder="请选择订单状态"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="item in stateOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <div class="btns-div">
            <el-button type="primary" :icon="Search" @click="filterEvent">查询</el-button>
            <el-button :icon="Download" @click="downloadEvent">导出</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <div class="information">
      当月销售金额<span>{{dataObj.salesAmount || 0}}</span>元，退款金额<span>{{dataObj.refundAmount || 0}}</span>元，累计收入<span>{{dataObj.totalAmount || 0}}</span>元，订单数<span>{{dataObj.totalOrder || 0}}</span>单
    </div>
    <div class="full-container">
      <TableContainer ref="TabelContainer" :listApiFunction="getList">
        <template #default="tbData">
          <el-table
            :height="tbData.data.tableHeight"
            :data="tbData.data.tableData"
            border
            stripe
            size="mini"
            header-row-class-name="theader"
            style="width: 100%"
          >
            <el-table-column prop="orderId" label="订单号" min-width="180">
            </el-table-column>
            <el-table-column
              prop="receiverName"
              label="收货人姓名"
              min-width="120"
            >
            </el-table-column>
            <el-table-column
              prop="memberPhone"
              label="收货人电话"
              min-width="120"
            >
            </el-table-column>
            <el-table-column prop="orderState" label="订单状态" min-width="100">
              <template #default="scope">
                {{ getOrderStateText(scope.row.orderState) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="totalAmount"
              label="总金额(元)"
              min-width="100"
            >
            </el-table-column>
            <el-table-column
              prop="paymentAmount"
              label="支付金额(元)"
              min-width="110"
            >
            </el-table-column>
            <el-table-column
              prop="discountAmount"
              label="优惠金额(元)"
              min-width="110"
            >
            </el-table-column>
            <el-table-column
              prop="orderNumber"
              label="商品数目"
              min-width="100"
            >
            </el-table-column>
            <el-table-column
              prop="createdTime"
              label="下单时间"
              min-width="180"
            >
              <template #default="scope">
                {{ timeYYYY_MM_DD_HH_mm_ss(scope.row.createdTime) }}
              </template>
            </el-table-column>
          </el-table>
        </template>
      </TableContainer>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import { Search, Plus, Download } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import TableContainer from "@/components/table-container/index";
import FormCheck from "@/utils/formCheck";
import report from "@/api/report";
import { timeYYYY_MM_DD_HH_mm_ss } from "@/utils/time-format.js";
import exportFile from "../../utils/exportFile";
import moment from 'moment'

const pageLoading = ref(false);
const RefFilterForm = ref(null);

const form = reactive({
  orderState: "",
  time: timeYYYY_MM_DD_HH_mm_ss(new Date()),
});
const disabledDate = (time) => {
  return time.getTime() > Date.now() - 8.64e7
}

const dataObj = ref({
  salesAmount: 0,
  refundAmount: 0,
  totalAmount: 0,
  totalOrder: 0,
})

function getList(data) {
  if(!data.time){
    data.time = form.time
  }
  return report.monthdetailed(data);
}
monthlistFunc()
function monthlistFunc() {
  report.monthlist({time: form.time}).then((res) => {
    if (res.code == 0) {
      if(res.data.length != 0){
        dataObj.value = res.data[0]
      }
    } else {
      ElMessage.error(res.msg);
    }
  });
}

const stateOptions = ref([
  {
    label: "待支付",
    value: 10,
  },
  {
    label: "待配送",
    value: 20,
  },
  {
    label: "配送中",
    value: 30,
  },
  {
    label: "已完成",
    value: 40,
  },
  {
    label: "已取消",
    value: 50,
  },
  {
    label: "已退款",
    value: 60,
  },
]);

function getOrderStateText(state) {
  if (!state) {
    return "";
  }
  let statItem =
    stateOptions.value.find((item) => {
      return item.value == state;
    }) || {};

  return statItem.label || "";
}

const TabelContainer = ref(null);

function filterEvent() {
  monthlistFunc();
  TabelContainer.value.filterEvent(form);
}

function downloadEvent() {

  let reportDate = moment(new Date(form.time)).format('YYYY-MM')
  let filename = '日账单_' + reportDate
  if (form.orderState) {
    filename += '_' + getOrderStateText(form.orderState)
  }

  exportFile.exportFilePost('/api/report/monthexcel', {
    time: form.time,
    orderState: form.orderState,
  }, filename)
}
</script>

<style lang="stylus" scoped type="text/stylus">
.order {
  >>> .dialog-content {
    max-height: 65vh;
    overflow-y: auto;
    padding-right: 40px;
  }

  .information {
    font-size: 14px;
    padding-bottom: 10px;
    padding-left: 10px;
    color: #888888;
    span {
      color red
      font-size: 20px
      margin 0 5px;
    }
  }
}
</style>